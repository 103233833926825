import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use'
import $ from 'jquery';

export default class extends Controller {

    static targets = [
        'content'
    ];

    static values = {
        'contentUrl': String,
        'hideLoadingIcon' : Boolean,
        'hideErrorMessage' : Boolean,
        'loadManually' : Boolean,
    }

    connect() {
        this.loaded = false;
        useIntersection(this);
    }

    appear() {
        let loadManually = this.loadManuallyValue;
        if (!this.loaded && !loadManually)
        {
            this.loadContent(this.contentUrlValue);
        }
    }

    disappear() {

    }

    loadContent()
    {
        this.loadContentFromUrl(this.contentUrlValue);
    }

    loadContentFromUrl(contentUrl)
    {
        let controller = this;
        let loader = ''
        let contentContainer = $(this.contentTarget);
        let hideErrorMessage = this.hideErrorMessageValue;

        if (!this.hideLoadingIconValue) {
            loader = "<div class=\"text-center my-5\"><i class=\"fas fa-spinner fa-spin fa-3x\"></i></div>"
            contentContainer.html(loader);
        }
        $.ajax({
            url: contentUrl,
            type: 'GET',
            success: function(response) {
                contentContainer.html(response);
                controller.loaded = true;
                $('[data-toggle="popover"]').popover();
                $('[data-toggle="tooltip"]').tooltip();
            },
            error: function () {
                if (!hideErrorMessage)
                {
                    contentContainer.html(
                        "<div class=\"my-5 text-center\">" +
                        "<i class=\"text-danger far fa-dizzy fa-3x\"></i>" +
                        "<div class=\"font-weight-medium h4 mt-4\">Ein Fehler ist aufgetreten</div>" +
                        "</div>"
                    );
                } else {
                    contentContainer.remove();
                }
            }
        });
    }

    loadNewContent(event) {
        this.loadContentFromUrl(event.params.url)
    }
}
