$(function() {
    $(this).on('click', '.confirm', function(e) {
        e.preventDefault();
        let hideCancelButton = $(this).hasClass('hide-cancel-button');
        let title = $(this).data('confirm-title') === undefined ? 'Achtung' : $(this).data('confirm-title');
        let confirmText = $(this).data('confirm-text') === undefined ? 'OK' : $(this).data('confirm-text');
        let cancelText = $(this).data('cancel-text') === undefined ? 'Abbrechen' : $(this).data('cancel-text');

        swal.fire({
            title: title,
            text: $(this).data('confirm-message'),
            showCancelButton: !hideCancelButton,
            confirmButtonColor: hideCancelButton ? '#999898' :'#a5be7c',
            cancelButtonColor: '#999898',
            confirmButtonText: confirmText,
            cancelButtonText: cancelText
        }).then((result) => {
            if (result.isConfirmed) {
                if ($(this).attr('href'))
                {
                    window.location = $(this).attr('href');
                }
                if ($(this).attr('type') === 'submit') {
                    let form = $(this).closest('form');
                    if (form.length > 0) {
                        form.submit();
                    }
                }
            }
        });
    });
});
