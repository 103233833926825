import ReadMore from "@stimulus-components/read-more";

export default class extends ReadMore {

    static targets = ["button"]

    connect() {
        super.connect();
        this.showMoreBtn()
    }

    showMoreBtn() {
        this.buttonTarget.classList.toggle("d-none", this.contentTarget.scrollHeight <= this.contentTarget.clientHeight);
    }

    toggle(event) {
        this.open === false ? this.show(event) : this.hide(event);
    }
}