// CSS
import '../website/css/fonts.css'
import 'flag-icon-css/css/flag-icon.min.css';
import 'cookieconsent/src/styles/base.css'
import 'cookieconsent/src/styles/layout.css'
import 'cookieconsent/src/styles/themes/classic.css'
import 'toastr/build/toastr.min.css'
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css');
import '../website_theme/plugins/selectric/selectric.css';
import '../website_theme/plugins/no-ui-slider/nouislider.css';
import '../website_theme/plugins/fancybox/jquery.fancybox.min.css';
import '../website_theme/plugins/isotope/isotope.css';
import '../website_theme/plugins/animate/animate.css';
import '../website_theme/plugins/menuzord/css/menuzord.css';
import '../website_theme/plugins/menuzord/css/menuzord-animations.css';
import '../website_theme/plugins/slick/slick.css';
import '../website_theme/plugins/slick/slick-theme.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'select2/src/scss/core.scss'
import '../website_theme/scss/star.scss';
import '../css/website_app.css';
import '../website/scss/website.scss';
// import '../website/scss/rotate_cardinal.scss';
import '../shared/upload_files/upload_file.css';
import '../website/scss/toastr.scss';
import '../website/scss/yacht_details.scss';
import '../website/scss/ribbon.scss'
import '../backoffice/scss/backoffice_custom_colors.scss'
import '../backoffice/scss/buttons.scss'
import '../website/scss/backoffice.scss'
import '../shared/custom_icons/style.css';
import '../shared/weather_icons/style.css';
import '../shared/star-rating.scss';
import '../shared/smiley-rating.scss';
import 'shariff/dist/shariff.min.css'
import '../website/scss/shariff.scss'
import '../website/custom/bubble/bubble.css'
import 'photoswipe/style.css';

// JS
const $ = require('jquery');
global.$ = global.jQuery = $;

import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
const routes = require('../../public/js/fos_js_routes.json');
Routing.setRoutingData(routes);
global.routing = Routing;

import toastr from 'toastr/build/toastr.min';
global.toastr = toastr;

global.swal = global.Swal = require('sweetalert2/dist/sweetalert2.min');

//import '../website_theme/plugins/bootstrap/js/bootstrap.bundle.min';
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
require('bootstrap-datepicker/js/bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.de');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB');

import '../website_theme/plugins/fancybox/jquery.fancybox';
import '../website_theme/plugins/isotope/isotope';
import 'select2';
import 'select2/dist/js/i18n/de';
import 'select2/dist/js/i18n/en';
import '../website_theme/plugins/menuzord/js/menuzord';
import 'cookieconsent/src/cookieconsent'
import '../website_theme/js/star';
import '../website/js/newsletter_registration';
import '../website/js/custom';
import '../website/js/modal_form';
import '../website/custom/bubble/bubble.js'
import '../website/bootstrap';
