import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {

    static targets = [
        'dateInput',
    ]

    static values = {
        'regionChoiceId': String,
        'days' : Array,
    }

    async connect() {
        this.initDatePicker(this.dateInputTarget, this.regionChoiceIdValue)
    }

    initDatePicker(datePicker, regionChoiceId) {
        let initializeDatepicker = this.initializeDatepicker;
        if (regionChoiceId !== '') {
            let regionChoice = '#' + regionChoiceId;
            let currentChoice = $(regionChoice).select2().find(":selected").data("days");
            if (currentChoice === undefined) {
                currentChoice = [6];
            }
            // Initialisiere den Datepicker mit der Standardoption (alle Tage auswählbar)
            if (currentChoice) {
                initializeDatepicker(datePicker, currentChoice);
            }
            $(document.body).on("change", regionChoice, function(element) {
                currentChoice = $(regionChoice).select2().find(":selected").data("days");
                if (currentChoice !== undefined) {
                    initializeDatepicker(datePicker, currentChoice);
                }
            });
        } else {
            initializeDatepicker(datePicker, this.daysValue)
        }
    }

    initializeDatepicker(datePicker, days) {
        let datePickerUser = $(datePicker);
        datePickerUser.datepicker('destroy'); // Zerstöre den bestehenden Datepicker, falls vorhanden
        datePickerUser.datepicker({
            autoclose: true,
            format: "dd.mm.yyyy",
            startDate: "today",
            todayHighlight: true,
            language: 'de',
            weekStart: 1,
            beforeShowDay: function(date) {
                if (days.includes(date.getDay())) {
                    let classes = '';
                    if (date.getDay() === 6) {
                        classes = 'highlight';
                    } else {
                        classes = 'highlight';
                    }

                    return {enabled: true, classes: classes};
                }

                return false; // Alle anderen Tage deaktivieren
            }
        });
    }
}